<template>
    <modal
        class="thanks-modal"
        :adaptive="true"
        name="thanks-modal"
        :min-height="700"
        width="600px"
    >
        <div class="close-modal" @click="$modal.hide('thanks-modal')">
            <img src="@assets/img/icons/close.svg" alt="X">
        </div>
        <div class="body">
            <h1>Great {{ invitedFounder.firstname }}!</h1>
            <p>Thank you for joining us! We are very excited to have you on board. We have sent you a confirmation email.</p>
        </div>
    </modal>
</template>

<script>
export default {
    name: "ThanksModal",
    props: {
        invitedFounder: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.thanks-modal {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            top: 30px;
            right: 20px;

            img {
                width: 30px;
            }
        }
    }

    /deep/ .vm--overlay {
        background: rgba(0, 0, 0, 0.5);
    }

    /deep/ .vm--modal {
        background-color: #2B2C2E;
        border-radius: 30px;

        @media(max-width: $sm) {
            border-radius: 0;
        }

        .body {
            padding: 100px;
            padding-bottom: 70px;
            background-image: url("~@assets/img/founders-landing/get-started-thanks.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 100%;

            @media(max-width: $sm) {
                padding: 50px 25px;
            }

            h1 {
                font-size: 42px;
                font-weight: 600;
                line-height: 100px;

                @media(max-width: $sm) {
                    font-size: 38px;
                    line-height: 50px;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 45px;

                @media(max-width: $sm) {
                    margin-bottom: 20px;
                }
            }

            .download-button {
                width: 60%;
                margin: 0 auto;

                a.btn {
                    margin-bottom: 15px;
                }

                span {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}
</style>
